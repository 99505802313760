/** @jsxImportSource theme-ui */
import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Link from "../components/Link";
import useSiteMetadata from "../hooks/use-site-metadata";
import { graphql, useStaticQuery } from "gatsby";
import { BgImage } from "gbimage-bridge";
import { getImage } from "gatsby-plugin-image";
import { Button, Container, useThemeUI, Card } from "theme-ui";
import { alpha } from "@theme-ui/color";
import { formatLocalDateTime, formatLocalDate } from "../utils/dateTime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Landing = (props) => {
  const {
    file: {
      childImageSharp: { gatsbyImageData },
    },
    articles,
    alarms,
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "header_background_firefighters.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
      articles: allMarkdownRemark(
        filter: { frontmatter: { templateName: { eq: "news/articles" } } }
        limit: 1
        sort: { fields: frontmatter___releasedAt, order: DESC }
      ) {
        edges {
          node {
            id
            excerpt
            fields {
              slug
            }
            frontmatter {
              title
              releasedAt
            }
          }
        }
      }
      alarms: allMarkdownRemark(
        filter: { frontmatter: { templateName: { eq: "news/alarms" } } }
        limit: 1
        sort: { fields: frontmatter___calledAt, order: DESC }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              calledAt
            }
          }
        }
      }
    }
  `);
  const latestArticle = articles?.edges[0]?.node;
  const latestAlarm = alarms?.edges[0]?.node;

  const { theme } = useThemeUI();
  const { displayTitle: title = "" } = useSiteMetadata();

  const firefighterImg = getImage(gatsbyImageData);
  const BG_OPACITY = 0.8;
  const bgColor1 = alpha("primary", BG_OPACITY)(theme);
  const bgColor2 = alpha("background", BG_OPACITY)(theme);
  const bgColor3 = alpha("secondary", BG_OPACITY)(theme);
  const bgImageStack = [
    `linear-gradient(60deg, ${bgColor1} -30%, ${bgColor2}, ${bgColor3} 130%)`,
    firefighterImg,
  ];

  return (
    <Layout {...props}>
      <Seo />
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          pt: ["10vh", "20vh"],
          pb: "10vh",
        }}
      >
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            minHeight: `100vh`,
            gridArea: ["1 / 1 / 3 / 2", "1 / 1 / 3 / 3"],
            zIndex: "backgroundImage",
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
          }}
        >
          <BgImage
            image={bgImageStack}
            alt="Feuerwehrmänner in Trümmern von abgebrannten Haus"
            sx={{
              height: "100%",
            }}
          ></BgImage>
        </div>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "wideContainer",
            gridArea: "main",
          }}
        >
          <div sx={{ textAlign: "center" }}>
            <h1
              sx={{
                variant: "text.siteTitle",
                mt: 0,
                mb: [2, 3],
                textShadow: (t) =>
                  `0 0 2.5rem ${t.colors.shade5}, 0 0 3.5rem ${t.colors.shade7}`,
                color: "primary",
                background: (t) =>
                  `-webkit-linear-gradient(-45deg, ${t.rawColors.primary}ee 40%, ${t.rawColors.secondary}fa 52%)`,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                WebkitTextStroke: (t) => `1px rgb(0 0 0 / 58%)`,
              }}
            >
              {title}
            </h1>
            <p
              sx={{
                variant: "text.lead",
                my: 2,
                textShadow: (t) => `0 0 3rem ${t.colors.shade7}`,
              }}
            >
              ehrenamtlich im Einsatz seit 1913 für den Ortsteil Arborn und
              Umgebung der Gemeinde Greifenstein im Lahn-Dill-Kreis.
            </p>
          </div>
        </Container>
        <Container sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
          <Link to="/ueber-uns">
            <Button>
              Erfahre mehr über uns <FontAwesomeIcon icon="chevron-right" />
            </Button>
          </Link>
        </Container>
        <Container
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {latestArticle && (
            <Link
              to={latestArticle.fields.slug}
              variant="card"
              sx={{
                color: "inherit",
                mt: 3,
                display: "flex",
                flex: "1 1 22em",
              }}
            >
              <Card variant="frostedGlass">
                <div sx={{ variant: "cards.sideHeader" }}>
                  <span>Letzte Neuigkeiten:</span>
                </div>
                <div sx={{ variant: "cards.container" }}>
                  <div>{latestArticle.frontmatter.title}</div>
                  <div sx={{ variant: "cards.footer" }}>
                    {formatLocalDate(latestArticle.frontmatter.releasedAt)}
                  </div>
                </div>
              </Card>
            </Link>
          )}
          {latestAlarm && (
            <Link
              to={latestAlarm.fields.slug}
              variant="card"
              sx={{
                color: "inherit",
                mt: 3,
                display: "flex",
                flex: "1 1 22em",
              }}
            >
              <Card variant="frostedGlass">
                <div sx={{ variant: "cards.sideHeader" }}>
                  <span>Letzter Einsatz:</span>
                </div>
                <div sx={{ variant: "cards.container" }}>
                  <div>{latestAlarm.frontmatter.title}</div>
                  <div sx={{ variant: "cards.footer" }}>
                    {formatLocalDateTime(latestAlarm.frontmatter.calledAt)}
                  </div>
                </div>
              </Card>
            </Link>
          )}
        </Container>
      </div>
    </Layout>
  );
};

export default Landing;
