export const formatLocalDate = date => {
  return new Date(date).toLocaleString("de-DE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  });
};

export const formatLocalDateTime = datetime => {
  return new Date(datetime).toLocaleString("de-DE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  });
};

export const formatLocalTime = datetime => {
  return new Date(datetime).toLocaleTimeString("de-DE", {
    hour: "2-digit",
    minute: "2-digit"
  });
};

export const getDate = (date, { day = true, month = true, year = true } = {}) =>
  date.toLocaleDateString("de-DE", {
    day: day ? "numeric" : undefined,
    month: month ? "long" : undefined,
    year: year ? "numeric" : undefined
  });
